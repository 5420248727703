import React, {memo, useRef} from 'react';
import {Page} from "../../components/page";
import {PromoSlider} from './sections/promo-slider';
import {Services} from './sections/services';
import {Devices} from './sections/devices';
import {Brands} from './sections/brands';
import {Instagram} from './sections/instagram';
import {Reviews} from './sections/reviews';
import {AboutUs} from './sections/about-us';
import {Team} from './sections/team';
import {BookButton} from "../../components/book-button";
import {useIntersectObserve} from "../../hooks";
import { Helmet } from 'react-helmet-async';
import logo from '../../assets/about-us/oglogo.jpg';



export const Clinic = memo(() => {

    return (

        <Page>
            <Helmet>
                <title>Leo beauty clinic | Klinika urody w Warszawie, Polsce</title>
                <meta name="description" content="Centrum odnowy biologicznej | Leobeautyclinic.pl | w Warszawie ✔️ Szeroki zakres usług ✔️ Doświadczeni specjaliści ✔️ Gwarancje jakości" />

                <meta property="og:title" content="Leo beauty clinic" />
                <meta property="og:description" content="Centrum odnowy biologicznej | Leobeautyclinic.pl | w Warszawie ✔️ Szeroki zakres usług ✔️ Doświadczeni specjaliści ✔️ Gwarancje jakości" />
                <meta property="og:image" content={`${window.location.origin}${logo}`} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
            </Helmet>
            <PromoSlider></PromoSlider>
            <AboutUs></AboutUs>
            <Services></Services>
            <Devices></Devices>
            <Reviews></Reviews>
            <Team></Team>
            <Brands></Brands>
            <Instagram></Instagram>
            <BookButton/>
        </Page>
    )
})